.mcqGrid::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  .mcqGrid::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 20px;
    margin-top: calc(100% + 90px);
    margin-bottom: calc(100% + 90px);
  }
  
  /* Handle */
  .mcqGrid::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 20px;
  }
  
  /* Handle on hover */
  .mcqGrid::-webkit-scrollbar-thumb:hover {
    background: #555;
  }