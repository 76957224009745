.slick-prev {
    left: 3% !important;
    z-index: 1;
  }
  .slick-next {
    right: 3% !important;
    z-index: 1;
  }

.slick-slider {
    display: flex;
    flex-direction: row;
}

.slick-slider > button {
    width: 180px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 160px;
}

.slick-slide > div > div {
  margin-left: auto !important;
  margin-right: auto !important;
}