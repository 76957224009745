body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar{
  width: 2px;
}

@font-face {
  font-family: 'PlusJakartaSans-Regular';
  src: local('PlusJakartaSans-Regular'), url(./fonts/PlusJakartaSans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-Italic';
  src: local('PlusJakartaSans-Italic'), url(./fonts/PlusJakartaSans-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-Bold';
  src: local('PlusJakartaSans-Bold'), url(./fonts/PlusJakartaSans-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-BoldItalic';
  src: local('PlusJakartaSans-BoldItalic'), url(./fonts/PlusJakartaSans-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-SemiBold';
  src: local('PlusJakartaSans-SemiBold'), url(./fonts/PlusJakartaSans-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-SemiBoldItalic';
  src: local('PlusJakartaSans-SemiBoldItalic'), url(./fonts/PlusJakartaSans-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-ExtraBold';
  src: local('PlusJakartaSans-ExtraBold'), url(./fonts/PlusJakartaSans-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-ExtraBoldItalic';
  src: local('PlusJakartaSans-ExtraBoldItalic'), url(./fonts/PlusJakartaSans-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-ExtraLight';
  src: local('PlusJakartaSans-ExtraLight'), url(./fonts/PlusJakartaSans-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-ExtraLightItalic';
  src: local('PlusJakartaSans-ExtraLightItalic'), url(./fonts/PlusJakartaSans-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-Light';
  src: local('PlusJakartaSans-Light'), url(./fonts/PlusJakartaSans-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-LightItalic';
  src: local('PlusJakartaSans-LightItalic'), url(./fonts/PlusJakartaSans-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-Medium';
  src: local('PlusJakartaSans-Medium'), url(./fonts/PlusJakartaSans-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'PlusJakartaSans-MediumItalic';
  src: local('PlusJakartaSans-MediumItalic'), url(./fonts/PlusJakartaSans-MediumItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'InterTight-Regular';
  src: local('InterTight-Regular'), url(./fonts/InterTight-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-Italic';
  src: local('InterTight-Italic'), url(./fonts/InterTight-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-Bold';
  src: local('InterTight-Bold'), url(./fonts/InterTight-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-BoldItalic';
  src: local('InterTight-BoldItalic'), url(./fonts/InterTight-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-SemiBold';
  src: local('InterTight-SemiBold'), url(./fonts/InterTight-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-SemiBoldItalic';
  src: local('InterTight-SemiBoldItalic'), url(./fonts/InterTight-SemiBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-ExtraBold';
  src: local('InterTight-ExtraBold'), url(./fonts/InterTight-ExtraBold.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-ExtraBoldItalic';
  src: local('InterTight-ExtraBoldItalic'), url(./fonts/InterTight-ExtraBoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-ExtraLight';
  src: local('InterTight-ExtraLight'), url(./fonts/InterTight-ExtraLight.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-ExtraLightItalic';
  src: local('InterTight-ExtraLightItalic'), url(./fonts/InterTight-ExtraLightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-Light';
  src: local('InterTight-Light'), url(./fonts/InterTight-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-LightItalic';
  src: local('InterTight-LightItalic'), url(./fonts/InterTight-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-Medium';
  src: local('InterTight-Medium'), url(./fonts/InterTight-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-MediumItalic';
  src: local('InterTight-MediumItalic'), url(./fonts/InterTight-MediumItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-Thin';
  src: local('InterTight-Thin'), url(./fonts/InterTight-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'InterTight-ThinItalic';
  src: local('InterTight-ThinItalic'), url(./fonts/InterTight-ThinItalic.ttf) format('truetype');
}

